<template>
  <div id="claim" class="mb-5">
    <PageHeader title="Key Claim"/>

    <div class="container container-boxed">
      <div class="row">
        <div class="col-12 col-lg-7">
          <section>
            <h2 class="display-2">Claim your <span class="highlight">free Shackers Keys</span></h2>
            <SignIn/>
          </section>
        </div>
        <div class="col-12 col-lg-5 decor text-center">
          <img src="../assets/img/claimPass/key.png" style="width: 95%"/>
          <TapeDivider rotation="up"/>
        </div>
      </div>

      <div class="row">
        <div class="col" v-if="shared.web3.connected && !shared.web3.connectedToRequiredNetwork">
          <section class="text-center">
            <WrongNetwork/>
          </section>
        </div>
        <div class="col" v-else-if="shared.web3.connected">
          <section class="text-center" v-if="claimEntry && !claimEntry.amount">
            <p>
              You claimed all your free keys already.
            </p>
            <a class="btn btn-primary btn-lg mb-3" href="https://opensea.io/collection/shackers-keys">
              Buy more on the marketplace
            </a>
          </section>
          <section class="text-center" v-else-if="claimEntry">
            <button class="btn btn-primary btn-lg mb-3" :disabled="!!currentTx" @click="claim()">
              Claim <span v-if="claimEntry.amount">{{ claimEntry.amount }}</span> free Key<span v-if="claimEntry.amount > 1">s</span>!
            </button>
          </section>
          <section class="text-center" v-else>
            <p>
              This wallet is not eligible for the free claim.
            </p>
            <a class="btn btn-primary btn-lg mb-3" href="https://opensea.io/collection/shackers-keys">
              Buy a key on the marketplace
            </a>
          </section>
        </div>
        <div class="col" v-else>
          <section class="text-center">
            <h3>Connect your wallet above and claim your key.</h3>
          </section>
        </div>
      </div>

      <div class="text-end small">
        <ContractUrl :contract-address="claimContractAddress"/>
      </div>
    </div>

    <Transition>
      <div class="migration-animation" :class="{'t': currentTxDone}" v-if="currentTx">
        <div class="anim-frame anim-frame--top"></div>
        <div class="anim-runners">
          <div class="container container-md">
            <img src="../assets/img/migration/portal.png" class="portal portal-base"/>

            <div class="runners-wrapper">
              <div class="runners">
                <img src="../assets/img/migration/run_all.gif"/>
              </div>

              <div class="effects"></div>
              <div class="bulb"></div>
              <div class="bulb-2"></div>

              <Transition>
                <h1 v-if="currentTxDone" class="text-center">SUCCESS<i class="icon-logo_icon"></i></h1>
              </Transition>
            </div>

            <img src="../assets/img/migration/portal_front.png" class="portal portal-front"/>

            <div class="text-center mt-3">
              <a class="btn btn-primary btn-info btn-lg external" :href="txUrl" target="_blank" v-if="!currentTxDone">
                View Transaction
              </a>
              <div v-else>
                <a class="btn btn-secondary btn-lg external me-2" :href="walletOpenSeaUrl" target="_blank">
                  Check on OpenSea
                </a>
                <button class="btn btn-primary btn-info btn-lg" @click="confirmTx">
                  Alright!
                </button>
              </div>
            </div>
          </div>
          <div class="portal-ground"></div>
        </div>
        <div class="anim-frame anim-frame--bottom"></div>
      </div>
    </Transition>

    <div class="container debug d-none">
      <button type="button" class="btn btn-primary me-2" @click="debugStartTx">
        Start TX
      </button>
      <button type="button" class="btn btn-primary me-2" @click="debugEndTx">
        End TX
      </button>
      <button type="button" class="btn btn-primary" @click="debugConfirmTx">
        Confirm TX
      </button>
    </div>
  </div>
</template>

<script>
import {Store} from "../store";
import ContractUrl from "../components/ContractUrl";
import PageHeader from "../components/PageHeader";
import SignIn from "../components/SignIn";
import TapeDivider from "../components/TapeDivider";
import {CLAIM_MINTPASS_CONTRACT_ADDRESS, createMintPassClaimContract} from "../contracts";
import WrongNetwork from "../components/WrongNetwork";
import {ethers} from "ethers";
import {openSeaWalletUrl, txUrl, extractWeb3ErrorMessage} from "../utils/web3";
import merkle from "../merkle/mintpass.free.test.merkle.json";

const findMerkleEntry = (address) => {
  if (ethers.utils.isAddress(address)) {
    return merkle.entries[ethers.utils.getAddress(address)]
  } else {
    return null
  }
}

export default {
  name: 'ClaimPage',
  components: {
    WrongNetwork,
    TapeDivider,
    ContractUrl,
    SignIn,
    PageHeader,
  },
  data() {
    return {
      shared: Store.state,
      currentTx: null,
      currentTxDone: false,
      claimAddress: null,
      claimEntry: null,
      claimContractAddress: CLAIM_MINTPASS_CONTRACT_ADDRESS
    }
  },
  mounted() {
    this.updateAddress(this.shared.web3.address);
  },
  watch: {
    "shared.web3.address"(newVal) {
      this.updateAddress(newVal);
    },
    "shared.web3.connectedToRequiredNetwork"() {
      this.updateAddress(this.shared.web3.address);
    }
  },
  computed: {
    txUrl: function () {
      return txUrl(this.currentTx);
    },
    walletOpenSeaUrl: function () {
      return openSeaWalletUrl(this.claimAddress, this.shared.web3);
    }
  },
  methods: {
    updateAddress: async function(newAddress) {
      this.claimAddress = newAddress;
      const claimEntry = findMerkleEntry(newAddress)
      if (claimEntry) {
        const claimContract = createMintPassClaimContract(this.shared.web3);
        const claimedAmount = (await claimContract.passIdToWalletToClaimedAmount(0, newAddress) || 0)
        this.claimEntry = {
          amount: Math.max(0, claimEntry.amount - claimedAmount.toNumber()),
          totalAmount: claimEntry.amount,
          proof: claimEntry.proof
        }
      }
    },
    awaitTx: async function (tx) {
      window.scrollTo(0, 0);
      this.currentTx = tx.hash;
      this.currentTxDone = false;
      await tx.wait();
      this.currentTxDone = true;
    },
    confirmTx: async function () {
      this.currentTx = null;
    },
    debugStartTx: async function () {
      window.scrollTo(0, 0);
      this.currentTx = "1";
      this.currentTxDone = false;
    },
    debugEndTx: async function () {
      this.currentTxDone = true;
    },
    debugConfirmTx: async function () {
      this.currentTx = null;
    },
    raiseError: function (e) {
      const message = extractWeb3ErrorMessage(e);
      // console.log("!", message, e);
      this.$notify({title: message, type: 'warn'})
    },
    claim: async function () {
      const claimContract = createMintPassClaimContract(this.shared.web3);

      if (!this.claimEntry) {
        this.raiseError({message: "This wallet is not eligible for the free claim."})
        return
      }

      try {
        const tx = await claimContract.claim(
            this.claimAddress,
            this.claimEntry.amount,
            this.claimEntry.totalAmount,
            this.claimEntry.proof
        );
        await this.awaitTx(tx);
        await this.updateAddress(this.shared.web3.address);
      } catch (e) {
        this.raiseError(e);
      }
    },
  }
}
</script>

<style scoped>
#claim {
  --accent-color: #00fda4;
  --action-background-color: #c124ff;
}

.decor img {
  max-width: 33%;
}

.decor .tape {
  margin-top: -0.5rem;
}

@media (min-width: 992px) {
  .decor {
    display: flex;
    flex-direction: column-reverse;
  }

  .decor .tape {
    margin-bottom: -2rem;
  }

  .decor img {
    max-width: 494px;
  }
}

.migration-animation {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  --animation-height: 25vw;
}

.claimed-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
}

.anim-frame {
  background: rgba(0, 0, 0, 0.9);
  flex-grow: 1;
}

.anim-runners {
  background: rgba(0, 255, 255, 0.8);
  position: relative;
}

.anim-runners, .anim-runners .container, .anim-runners .runners-wrapper, .portal {
  height: var(--animation-height);
  /*max-height: 70vh;*/
}

.anim-runners .container {
  position: relative;
}

.anim-runners .runners-wrapper {
  overflow: hidden;
  position: relative;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.runners-wrapper > div {
  position: absolute;
  bottom: 0;
}

.runners-wrapper .runners {
  height: 50%;
  width: 100%;
}

.portal {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(25%);
}

.portal-ground {
  height: 0.75vh;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
}

.runners img {
  height: 100%;
  margin-bottom: 0.1vh;
}

.effects {
  height: 100%;
  width: 100%;
  animation: flicker 700ms infinite ease-in-out alternate;
  background: radial-gradient(farthest-side at right bottom, #1529ed, #0000);
  -webkit-mask: conic-gradient(from 98deg at center right, #00000000, #000 149deg 194deg, #0000 263deg);
  mix-blend-mode: hard-light;
}

.bulb {
  width: calc(var(--animation-height) * 3 / 4);
  height: 70%;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  background: radial-gradient(farthest-side at center, #4fe5f4, #0000);
  mix-blend-mode: plus-lighter;
  animation: wobble 5.2s infinite linear;
}

.bulb-2 {
  width: calc(var(--animation-height) / 9);
  height: 70%;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  background: radial-gradient(farthest-side at center, white, #ffffff00);
  mix-blend-mode: plus-lighter;
}

.migration-animation.t .runners {
  animation: portal 1800ms linear;
  animation-iteration-count: 1;
  transform-origin: bottom center;
  animation-fill-mode: forwards;
}

.migration-animation .tokens > * {
  display: none;
}

.migration-animation .tokens .selected {
  display: block;
}

.debug {
  bottom: 0;
  position: fixed;
  z-index: 1000;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@keyframes portal {
  from {
    transform: translate(0, 0) skewX(0);
    transform-origin: bottom center;
  }
  80% {
    transform: translate(70%, 0%) skewX(-10deg);
    transform-origin: bottom center;
  }
  95% {
    transform: translate(80%, -20%) skewX(-80deg);
    transform-origin: bottom center;
  }
  97% {
    transform: translate(90%, -40%) skewX(-100deg);
    transform-origin: right;
  }
  to {
    transform: translate(90%, -40%) skewX(-90deg);
    transform-origin: right;
  }
}

@keyframes flicker {
  from {
    opacity: 0.9;
  }
  to {
    opacity: 1;
  }
}

@keyframes wobble {
  from {
    transform: translateX(50%) rotate(0);
  }
  50% {
    transform: translateX(70%) rotate(-360deg);
  }
  to {
    transform: translateX(50%) rotate(-720deg);
  }
}

</style>
