import {app, IS_DEV} from "../firebase";
import {
  ensureInitialized,
  getAll,
  getBoolean,
  getNumber,
  getString,
  getRemoteConfig,
  getValue,
  fetchAndActivate
} from "firebase/remote-config";

const config = getRemoteConfig(app);

// quicker fetch interval for testing purposes
// default (in production) is 12 hours
// https://firebase.google.com/docs/remote-config/get-started?platform=web#throttling
if (IS_DEV) {
  config.settings.minimumFetchIntervalMillis = 1000 * 60; // 1 minute
}

export const flags = {
  initialize() {
    return fetchAndActivate(config)
      .then(async () => {
        if (IS_DEV) {
          console.log("Feature Flags initialized", JSON.stringify(getAll(config), null, 2));
        }
        await ensureInitialized(config);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  },
  getValue(key) {
    return getValue(config, key);
  },
  getBoolean(key) {
    return getBoolean(config, key);
  },
  getNumber(key) {
    return getNumber(config, key);
  },
  getString(key) {
    return getString(config, key);
  },
};
