import detectEthereumProvider from '@metamask/detect-provider'
import WalletConnectProvider from "@walletconnect/web3-provider";
import {ethers} from "ethers";
import {reactive, toRaw} from "vue";
import {WalletLink} from 'walletlink';
import Web3Modal from "web3modal";

const web3ModalProviderOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.VUE_APP_INFURA_ID,
    }
  },
  'custom-walletlink': {
    display: {
      logo: '/images/coinbase-wallet.svg',
      name: 'Coinbase (WalletLink)',
      description: 'Scan with WalletLink to connect',
    },
    options: {
      appName: 'Shackers',
      networkUrl: `https://mainnet.infura.io/v3/${process.env.VUE_APP_INFURA_ID}`,
      chainId: 1,
    },
    package: WalletLink,
    connector: async (_, options) => {
      const {appName, networkUrl, chainId} = options
      const walletLink = new WalletLink({
        appName
      });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      return provider;
    },
  },
};

export const REQUIRED_NETWORK = process.env.VUE_APP_ETH_NETWORK;

const INITIAL_WEB3_STATE = {
  address: "",
  provider: null,
  web3Provider: null,
  connected: false,
  connectedToRequiredNetwork: false,
  networkChainId: 1,
  networkName: "",
};

export const Store = reactive({
  web3Modal: null,
  state: {
    web3: INITIAL_WEB3_STATE,
    web3ProviderRaw() {
      return toRaw(this.web3.web3Provider)
    }
  },
  login(web3State) {
    this.state.web3 = web3State;
    this.checkNetwork();

    try {
      localStorage.setItem('shackers-logged-in', "true");
    } catch (e) {
      console.log("LocalStorage not supported", e);
    }
  },
  logout() {
    this.state.web3 = INITIAL_WEB3_STATE;
    try {
      localStorage.setItem('shackers-logged-in', "false");
    } catch (e) {
      console.log("LocalStorage not supported", e);
    }
  },
  tryAutoConnectWallet: async function () {
    let isLoggedIn;
    try {
      isLoggedIn = localStorage.getItem('shackers-logged-in') === "true";
    } catch (e) {
      console.log("LocalStorage not supported");
    }

    if (isLoggedIn) {
      try {
        const provider = await detectEthereumProvider();
        await this.connectWallet(provider);
      } catch (e) {
        console.log("No ethereum provider found. Please connect manually.", e);
      }
    }
  },
  connectWalletModal: async function () {
    this.web3Modal = new Web3Modal({
      // network: "mainnet", // optional
      cacheProvider: false, // optional
      providerOptions: web3ModalProviderOptions, // required
      theme: "dark"
    });
    await this.connectWallet(await this.web3Modal.connect());
  },
  connectWallet: async function (provider) {
    const web3Provider = new ethers.providers.Web3Provider(provider);
    const accounts = await web3Provider.listAccounts();
    const address = accounts[0];
    const network = await web3Provider.getNetwork();

    subscribeToProviderEvents(provider);
    this.login({
      provider,
      web3Provider,
      connected: true,
      connectedToRequiredNetwork: true,
      address,
      networkChainId: network.chainId,
      networkName: network.name,
    });
  },
  resetConnection: async function () {
    if (this.web3Modal) {
      await this.web3Modal.clearCachedProvider();
    }
    this.logout();
  },
  switchToRequiredNetwork: function () {
    // const web3Provider = new ethers.providers.Web3Provider(Store.state.web3.provider);
    // noop
  },
  checkNetwork: function () {
    //console.log(`Check network. Current: ${this.state.web3.chainName} Required: ${REQUIRED_NETWORK}`)
    let correctNetwork = true;
    switch (REQUIRED_NETWORK) {
      case "localhost":
        correctNetwork = `${this.state.web3.networkChainId}`.endsWith("1337") // ganache(1337) and hardhat(31337)
        break;
      case "mainnet":
        correctNetwork = this.state.web3.networkChainId === 1;
        break;
      default:
        correctNetwork = REQUIRED_NETWORK.toLowerCase() === this.state.web3.networkName.toLowerCase();
        break;
    }

    this.state.web3.connectedToRequiredNetwork = correctNetwork;
  }
});

const subscribeToProviderEvents = function (provider) {
  // Subscribe to accounts change
  provider.on("accountsChanged", (accounts) => {
    console.log("accountsChanged", accounts);
    Store.state.web3.address = accounts[0];
  });

  // Subscribe to chainId change
  provider.on("chainChanged", async (chainId) => {
    const web3Provider = new ethers.providers.Web3Provider(Store.state.web3.provider);
    Store.state.web3.web3Provider = web3Provider;

    const network = await web3Provider.getNetwork();
    Store.state.web3.networkChainId = network.chainId;
    Store.state.web3.networkName = network.name;
    console.log("chainChanged", Number(chainId), chainId, Store.state.web3.networkName);
    Store.checkNetwork();
  });

  // // Subscribe to provider connection
  // provider.on("connect", (info) => {
  //   console.log("connect", info);
  // });
  //
  // // Subscribe to provider disconnection
  // provider.on("disconnect", (error) => {
  //   console.log("disconnect", error);
  // });
}
