<script setup>
import {computed, defineProps, ref, toRef} from 'vue'
import {loaderColors} from "../utils/colors";

const validSizes = new Set(["small", "normal", "large"])
const loaderColorKeys = Object.keys(loaderColors);
const validColors = new Set(loaderColorKeys)

const props = defineProps({
  color: {
    type: String,
    default: "random"
  },
  loaderText: {
    type: String,
    default: "Loading.."
  },
  size: {
    type: String,
    default: "normal"
  },
  delay: {
    type: Number,
    default: 0
  },
})

toRef(props, 'loaderText');

// only have
const isVisible = ref(false);
setTimeout(() => isVisible.value = true, props.delay);

const selectedSize = computed(() => validSizes.has(props.size) ? props.size : "normal");

const selectedColor = computed(() => props.color === "random"
    ? loaderColorKeys[Math.floor(Math.random() * loaderColorKeys.length)]
    : (validColors.has(props.color) ? props.color : "red"));
</script>

<template>
  <transition>
    <span v-if="isVisible" class="d-inline-block text-center loader" :class="`${selectedSize}Loader`">
      <img :src="require(`@/assets/img/loader/${selectedColor}.gif`)" :alt="`Loading Indicator: Burning ${selectedColor} Flame`"/>
      <span v-if="loaderText" :style="{color: loaderColors[selectedColor]}"><br>{{ loaderText }}</span>
    </span>
  </transition>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 500ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.loader {
  padding: 0 .75rem .75rem;
}

.smallLoader img {
  max-width: 35rem;
}

.normalLoader img {
  max-width: 56px;
}

.largeLoader img {
  max-width: 112px;
}

@media (min-width:576px) {
  .smallLoader img {
    max-width: 3rem;
  }

  .normalLoader img {
    max-width: 112px;
  }

  .largeLoader img {
    max-width: 224px;
  }
}
</style>
