<template>
  <div id="randomizer">
    <PageHeader :title="`Rando*${randomRequestId || ''}`"/>

    <div class="container">
      <Transition name="fade" mode="out-in">
        <div v-if="!shared.web3.connected" key="connect">
          <div class="container text-center">
            <SignIn/>
          </div>
        </div>
        <div v-else-if="randomRequestLoading" key="loading">
          <LoadingIndicator />
        </div>
        <div v-else-if="!randomRequest" key="404">
          <h2>This request has not been made yet...</h2>
        </div>
        <div v-else key="loaded">
          <h2 class="display-2">
            Requested
            <span class="highlight">{{ randomRequest.count }} number<span v-if="randomRequest.count > 1">s</span></span>
            <span v-if="randomRequest.min > 0">
            between
            <span class="highlight">{{ randomRequest.min }} and {{ randomRequest.max }}</span>
          </span>
            for <span class="highlight">{{ randomRequest.purpose }}</span>
          </h2>

          <Transition name="fade" mode="out-in">
            <div v-if="randomRequest.pending">
              Waiting for results...
            </div>
            <div v-else class="mt-5 flex flex-column align-items-center">
              <span class="highlight display-2 result-entry d-inline-block text-center w-100"
                    v-if="randomRequest.count === 1"
                    :title="randomRequestResults[0]">
                {{ randomRequestResults[0] }}
              </span>
              <ol v-else class="w-100 d-inline-block text-center">
                <li v-for="num of randomRequestResults" :key="num.toString()" :title="num"
                    class="result-entry highlight display-2">
                  {{ num }}
                </li>
              </ol>
            </div>
          </Transition>
        </div>
      </Transition>
      <div class="text-center small mt-5">
        <ContractUrl :contract-address="contractAddress" />
      </div>
    </div>
  </div>
</template>

<script>
import SignIn from "../components/SignIn";
import {Store} from "../store";
import {createRandomizerContract} from "../contracts";
import ContractUrl from "../components/ContractUrl";
import PageHeader from "../components/PageHeader";
import LoadingIndicator from "../components/LoadingIndicator";

export default {
  name: 'RandomizerPage',
  components: {
    LoadingIndicator,
    PageHeader,
    ContractUrl,
    SignIn,
  },
  data() {
    return {
      shared: Store.state,
      randomizerContract: null,
      randomRequestId: null,
      randomRequestLoading: false,
      randomRequest: {},
      randomRequestResults: [],
      contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS_RANDOMIZER
    }
  },
  created() {
    this.randomRequestId = this.$route.params.requestId;
    this.$watch(() => this.$route.params, (toParams) => {
      this.randomRequestId = toParams.requestId;
    });
  },
  watch: {
    'shared.web3.connected': async function () {
      await this.initialize();
    },
    randomRequestId: async function () {
      await this.initialize();
    }
  },
  methods: {
    initialize: async function () {
      if (this.shared.web3.connected) {
        this.randomizerContract = createRandomizerContract(this.shared.web3);
        await this.loadRequest(this.randomRequestId)
      }
    },
    loadRequest: async function (requestId) {
      if (!requestId) return;

      console.log(`Loading request ${requestId}`);
      try {
        this.randomRequestLoading = true;
        const request = await this.randomizerContract.requests(`${requestId}`);

        if (request.count === 0) {
          this.randomRequest = null;
          this.randomRequestLoading = false;
        } else if (request.pending) {
          this.randomRequest = request;
          setTimeout(() => this.loadRequest(requestId), 2000);
        } else {
          this.randomRequest = request;
          this.randomRequestLoading = false;
          await this.loadResults();
        }
      } catch (e) {
        console.log(e);
        this.randomRequest = null;
        this.randomRequestLoading = false;
      }
    },
    loadResults: async function () {
      const requestId = this.randomRequestId;
      const amount = this.randomRequest.count;

      this.randomRequestResults = [];
      for (let i = 0; i < amount; i++) {
        this.randomRequestResults.push(await this.randomizerContract.results(requestId, i));
      }
    }
  }
};
</script>

<style>
#randomizer {
  --accent-color: lime;
  --action-background-color: lime;
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.result-entry {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
