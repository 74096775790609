<template>
  <div id="claim" class="mb-5">
    <PageHeader title="OG Claim"/>

    <div class="container container-boxed">
      <div class="row">
        <div class="col-12 col-lg-7">
          <section>
            <h2 class="display-2">Claim your <span class="highlight">free Shackers: Age Of Flame</span> via select NFT</h2>
            <p>Qualifying collections include:</p>
            <ul class="mb-5">
              <li>
                <a href="https://opensea.io/collection/shackers-og" target="_blank">OG Shackers</a>
                <small>
                  (
                  <router-link to="og/migrate">migrated</router-link>
                  from our <a href="https://opensea.io/collection/shackers">original collection</a>)
                </small>
              </li>
              <li><a href="https://opensea.io/collection/cryptoweewees" target="_blank">Crypto Weewees</a></li>
              <li><a href="https://opensea.io/collection/loltapes" target="_blank">LolTapes</a></li>
            </ul>

            <div v-if="shared.web3.connectedToRequiredNetwork" class="mb-2">
              <div class="input-group mb-2">
                <button v-if="claimAddress !== shared.web3.address" class="btn btn-secondary" type="button"
                        @click="resetAddress">
                  <i class="bi bi-arrow-counterclockwise"></i>
                </button>
                <input type="text" class="form-control"
                       placeholder="Claim Wallet Address"
                       aria-label="Claim Wallet Address"
                       v-model="claimAddress">
                <button class="btn btn-primary" type="button" @click="load">Check Wallet</button>
              </div>
              <span class="text-dark">
                <span class="badge rounded-pill bg-highlight text-dark align-middle">Tip</span>
                Claim for your cold wallet by entering its address.
              </span>

              <h2 class="text-center mt-3">&mdash; OR &mdash;</h2>

              <div class="input-group w-auto mt-3 mb-2">
                <select class="form-select" v-model="singleCheckType">
                  <option v-for="type of Object.keys(ogCollections)" v-bind:key="type" :value="type">
                    {{ogCollections[type].title}}
                  </option>
                </select>
                <input type="text" class="form-control"
                       placeholder="Token ID"
                       aria-label="Token ID"
                       v-model="singleCheckTokenId">
                <button class="btn btn-secondary" type="button"
                        @click="checkClaimedSingle(singleCheckType, singleCheckTokenId)">
                  Check single
                </button>
              </div>
              <span class="mb-2 text-dark">
                Enter the long OpenSea Token ID for LolTapes and Crypto WeeWees.
              </span>
            </div>

            <SignIn/>
          </section>
        </div>
        <div class="col-12 col-lg-5 decor text-center">
          <img src="../assets/img/claim/ogs.png" style="width: 95%"/>
          <TapeDivider rotation="up"/>
        </div>
      </div>

      <div class="row">
        <div class="col" v-if="shared.web3.connected && !shared.web3.connectedToRequiredNetwork">
          <section class="text-center">
            <WrongNetwork/>
          </section>
        </div>
        <div class="col" v-else-if="loadAndDisplayAssets">
          <section>
            <p class="text-end">
              <a class="btn btn-secondary mb-2" @click.prevent="selectAll()">Select All</a>
              <a class="btn btn-secondary mb-2 ms-2" @click.prevent="selectNone()">Select None</a>
            </p>
          </section>

          <CollectionAssets
              v-for="config in ogCollections"
              v-bind:key="config.id"
              :ref="config.id"
              :title="config.title"
              :slug="config.slug"
              :hide-controls="true"
              :owner-address="claimAddress"
              :marks="assetIdMarks[config.id]"
              @assetsLoad="(items) => checkClaimed(config.id, items)"
              @selectionUpdate="(items) => select(config.id, items)"
          >
            <template v-slot:title>
              <h3><span class="highlight">{{ config.title }}</span> - Claim {{ config.freeMints }} free per NFT</h3>
            </template>
            <template #mark="markProps">
              <div class="claimed-overlay">
                <LoadingIndicator
                    :color="markProps.mark === true ? 'red' : 'green'"
                    :loader-text="markProps.mark === true ? 'ALREADY CLAIMED' : 'Checking claim status'"
                />
              </div>
            </template>
          </CollectionAssets>

          <section class="text-center" v-if="!currentTx">
            <a
                class="btn btn-primary btn-lg mb-3"
                :class="{'disabled': selectedTokensCount === 0}"
                @click.prevent="claim()">
              Claim <span v-if="claimableShackersCount">{{ claimableShackersCount }}</span> free Shackers!
            </a>
            <br>
            <small v-if="claimAddress !== shared.web3.address">
              They will be claimed and minted to address
              <span class="highlight">{{ $filters.shortAddress(claimAddress) }}</span>.
              <br>
              <SignIn/>
            </small>
          </section>
        </div>
        <div class="col" v-else>
          <section class="text-center">
            <h3>Connect your wallet above and load tokens to claim.</h3>
          </section>
        </div>
      </div>

      <div class="text-end small">
        <ContractUrl :contract-address="claimContractAddress"/>
      </div>
    </div>

    <Transition>
      <div class="migration-animation" :class="{'t': currentTxDone}" v-if="currentTx">
        <div class="anim-frame anim-frame--top"></div>
        <div class="anim-runners">
          <div class="container container-md">
            <img src="../assets/img/migration/portal.png" class="portal portal-base"/>

            <div class="runners-wrapper">
              <div class="runners">
                <img src="../assets/img/migration/run_all.gif"/>
              </div>

              <div class="effects"></div>
              <div class="bulb"></div>
              <div class="bulb-2"></div>

              <Transition>
                <h1 v-if="currentTxDone" class="text-center">SUCCESS<i class="icon-logo_icon"></i></h1>
              </Transition>
            </div>

            <img src="../assets/img/migration/portal_front.png" class="portal portal-front"/>

            <div class="text-center mt-3">
              <a class="btn btn-primary btn-info btn-lg external" :href="txUrl" target="_blank" v-if="!currentTxDone">
                View Transaction
              </a>
              <div v-else>
                <a class="btn btn-secondary btn-lg external me-2" :href="walletOpenSeaUrl" target="_blank">
                  Check on OpenSea
                </a>
                <button class="btn btn-primary btn-info btn-lg" @click="confirmTx">
                  Alright!
                </button>
              </div>
            </div>
          </div>
          <div class="portal-ground"></div>
        </div>
        <div class="anim-frame anim-frame--bottom"></div>
      </div>
    </Transition>

    <div class="container debug d-none">
      <button type="button" class="btn btn-primary me-2" @click="debugStartTx">
        Start TX
      </button>
      <button type="button" class="btn btn-primary me-2" @click="debugEndTx">
        End TX
      </button>
      <button type="button" class="btn btn-primary" @click="debugConfirmTx">
        Confirm TX
      </button>
    </div>
  </div>
</template>

<script>
import {Store} from "../store";
import ContractUrl from "../components/ContractUrl";
import PageHeader from "../components/PageHeader";
import SignIn from "../components/SignIn";
import TapeDivider from "../components/TapeDivider";
import {CLAIM_CONTRACT_ADDRESS, createClaimContract} from "../contracts";
import CollectionAssets from "../components/CollectionAssets";
import LoadingIndicator from "../components/LoadingIndicator";
import WrongNetwork from "../components/WrongNetwork";
import {ethers} from "ethers";
import {openSeaWalletUrl, txUrl} from "../utils/web3";

const initialSelectedTokenIds = {
  "shackers": [],
  "weewees": [],
  "loltapes": [],
}

const initialAssetIdMarks = {
  "shackers": {},
  "weewees": {},
  "loltapes": {},
}

export default {
  name: 'ClaimPage',
  components: {
    WrongNetwork,
    LoadingIndicator,
    CollectionAssets,
    TapeDivider,
    ContractUrl,
    SignIn,
    PageHeader,
  },
  data() {
    return {
      shared: Store.state,
      loadAndDisplayAssets: false,
      ogCollections: {
        'shackers': {
          id: 'shackers',
          title: 'OG Shackers',
          slug: 'shackers-og',
          mintFn: 'mintViaOg',
          isClaimedFn: 'isOgClaimed',
          freeMints: 1,
        },
        'weewees': {
          id: 'weewees',
          title: 'Crypto WeeWees',
          slug: 'cryptoweewees',
          mintFn: 'mintViaWeeWees',
          isClaimedFn: 'isWeeWeeClaimed',
          freeMints: 1,
        },
        'loltapes': {
          id: 'loltapes',
          title: 'LolTapes',
          slug: 'loltapes',
          mintFn: 'mintViaLolTapes',
          isClaimedFn: 'isLolTapeClaimed',
          freeMints: 1,
        }
      },
      selectedTokenIds: initialSelectedTokenIds,
      assetIdMarks: initialAssetIdMarks,
      currentTx: null,
      currentTxDone: false,
      claimAddress: null,
      claimContractAddress: CLAIM_CONTRACT_ADDRESS,
      singleCheckType: 'shackers',
      singleCheckTokenId: '',
    }
  },
  watch: {
    "shared.web3.address"(newVal) {
      this.loadAndDisplayAssets = false;
      this.claimAddress = newVal;
    }
  },
  computed: {
    claimableShackersCount: function () {
      return this.selectedTokenIds && (
          this.selectedTokenIds['shackers'].length * this.ogCollections['shackers'].freeMints +
          this.selectedTokenIds['weewees'].length * this.ogCollections['weewees'].freeMints +
          this.selectedTokenIds['loltapes'].length * this.ogCollections['loltapes'].freeMints
      );
    },
    selectedTokensCount: function () {
      return this.selectedTokenIds && (
          this.selectedTokenIds['shackers'].length +
          this.selectedTokenIds['weewees'].length +
          this.selectedTokenIds['loltapes'].length
      );
    },
    txUrl: function () {
      return txUrl(this.currentTx);
    },
    walletOpenSeaUrl: function () {
      return openSeaWalletUrl(this.claimAddress, this.shared.web3);
    }
  },
  methods: {
    resetAddress() {
      this.claimAddress = this.shared.web3.address;
    },
    validateAddress: async function () {
      const needValidAddressError = "Please input a valid ETH address.";
      if (!this.claimAddress) {
        this.$notify({title: needValidAddressError, type: 'warn'})
        return false;
      } else if (this.claimAddress.endsWith(".eth")) {
        try {
          this.claimAddress = await this.shared.web3ProviderRaw().resolveName(this.claimAddress);
          return this.validateAddress();
        } catch (e) {
          this.raiseError(e);
          return false;
        }
      } else {
        try {
          ethers.utils.getAddress(this.claimAddress);
          return true;
        } catch (e) {
          this.$notify({title: needValidAddressError, type: 'warn'})
          return false;
        }
      }
    },
    load: async function () {
      if (!await this.validateAddress()) return;

      this.loadAndDisplayAssets = false;
      this.selectedTokenIds = initialSelectedTokenIds;
      this.assetIdMarks = initialAssetIdMarks;
      this.$nextTick(() => (this.loadAndDisplayAssets = true));
    },
    selectAll() {
      Object.keys(this.ogCollections).map((refName) => this.$refs[refName][0].selectAll());
    },
    selectNone() {
      Object.keys(this.ogCollections).map((refName) => this.$refs[refName][0].selectNone());
    },
    select(type, assets) {
      this.selectedTokenIds[type] = assets;
    },
    checkClaimedSingle: async function (type, id) {
      if (this.shared.web3.connected && id && type) {
        const claimContract = createClaimContract(this.shared.web3);
        try {
          console.log(`Checking ${type} token id ${id}...`);
          const isClaimed = await claimContract[this.ogCollections[type].isClaimedFn](id);
          console.log(`... is claimed?`, isClaimed);
          this.$notify({
            title: `${this.ogCollections[type].title} ${id} is ${isClaimed ? '' : 'not yet'} claimed.`,
            type: isClaimed ? 'error' : 'success'
          })
        } catch (e) {
          this.raiseError(new Error(`Error checking claim status. Did you enter the proper NFT Token ID?`));
        }
      }
    },
    checkClaimed: async function (type, assets) {
      if (this.shared.web3.connected && assets) {
        const claimContract = createClaimContract(this.shared.web3);
        for (let i = 0; i < assets.length; i++) {
          const asset = assets[i];
          const id = asset.tokenId;
          this.assetIdMarks[type][id] = "Loading";

          try {
            console.log(`Checking token id ${id}...`);
            const isClaimed = await claimContract[this.ogCollections[type].isClaimedFn](id);
            this.assetIdMarks[type][id] = isClaimed;
            console.log(`... is claimed?`, isClaimed);
          } catch (e) {
            this.raiseError(new Error(`Claimed Status could not be checked. ${e.message}`));
            this.assetIdMarks[type][id] = false;
            break;
          }
        }
      }
    },
    awaitTx: async function (tx) {
      window.scrollTo(0, 0);
      this.currentTx = tx.hash;
      this.currentTxDone = false;
      await tx.wait();
      this.currentTxDone = true;
    },
    confirmTx: async function () {
      this.currentTx = null;
    },
    debugStartTx: async function () {
      window.scrollTo(0, 0);
      this.currentTx = "1";
      this.currentTxDone = false;
    },
    debugEndTx: async function () {
      this.currentTxDone = true;
    },
    debugConfirmTx: async function () {
      this.currentTx = null;
    },
    raiseError: function (e) {
      const message = e.reason || e.message;
      // console.log("!", message, e);
      this.$notify({title: message, type: 'warn'})
    },
    claim: async function () {
      const claimContract = createClaimContract(this.shared.web3);
      const claimContractInterface = claimContract.interface;
      const recipient = this.claimAddress;

      const multicallData = [];
      Object.keys(this.ogCollections).forEach((type) => {
        const selectedIds = this.selectedTokenIds[type];
        if (selectedIds && selectedIds.length > 0) {
          const mintFnName = this.ogCollections[type].mintFn;
          multicallData.push(
              claimContractInterface.encodeFunctionData(mintFnName, [recipient, selectedIds])
          )
        }
      });

      if (multicallData.length === 0) {
        this.raiseError(new Error("Please select some tokens to claim"));
        return;
      }

      try {
        const tx = await claimContract.multicall(multicallData);
        await this.awaitTx(tx);
        await this.load();
      } catch (e) {
        this.raiseError(e);
      }
    },
  }
}
</script>

<style scoped>
#claim {
  --accent-color: #00fda4;
  --action-background-color: #c124ff;
}

.decor img {
  max-width: 33%;
}

.decor .tape {
  margin-top: -0.5rem;
}

@media (min-width: 992px) {
  .decor {
    display: flex;
    flex-direction: column-reverse;
  }

  .decor .tape {
    margin-bottom: -2rem;
  }

  .decor img {
    max-width: 494px;
  }
}

.migration-animation {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  --animation-height: 25vw;
}

.claimed-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
}

.anim-frame {
  background: rgba(0, 0, 0, 0.9);
  flex-grow: 1;
}

.anim-runners {
  background: rgba(0, 255, 255, 0.8);
  position: relative;
}

.anim-runners, .anim-runners .container, .anim-runners .runners-wrapper, .portal {
  height: var(--animation-height);
  /*max-height: 70vh;*/
}

.anim-runners .container {
  position: relative;
}

.anim-runners .runners-wrapper {
  overflow: hidden;
  position: relative;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.runners-wrapper > div {
  position: absolute;
  bottom: 0;
}

.runners-wrapper .runners {
  height: 50%;
  width: 100%;
}

.portal {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(25%);
}

.portal-ground {
  height: 0.75vh;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
}

.runners img {
  height: 100%;
  margin-bottom: 0.1vh;
}

.effects {
  height: 100%;
  width: 100%;
  animation: flicker 700ms infinite ease-in-out alternate;
  background: radial-gradient(farthest-side at right bottom, #1529ed, #0000);
  -webkit-mask: conic-gradient(from 98deg at center right, #00000000, #000 149deg 194deg, #0000 263deg);
  mix-blend-mode: hard-light;
}

.bulb {
  width: calc(var(--animation-height) * 3 / 4);
  height: 70%;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  background: radial-gradient(farthest-side at center, #4fe5f4, #0000);
  mix-blend-mode: plus-lighter;
  animation: wobble 5.2s infinite linear;
}

.bulb-2 {
  width: calc(var(--animation-height) / 9);
  height: 70%;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  background: radial-gradient(farthest-side at center, white, #ffffff00);
  mix-blend-mode: plus-lighter;
}

.migration-animation.t .runners {
  animation: portal 1800ms linear;
  animation-iteration-count: 1;
  transform-origin: bottom center;
  animation-fill-mode: forwards;
}

.migration-animation .tokens > * {
  display: none;
}

.migration-animation .tokens .selected {
  display: block;
}

.debug {
  bottom: 0;
  position: fixed;
  z-index: 1000;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@keyframes portal {
  from {
    transform: translate(0, 0) skewX(0);
    transform-origin: bottom center;
  }
  80% {
    transform: translate(70%, 0%) skewX(-10deg);
    transform-origin: bottom center;
  }
  95% {
    transform: translate(80%, -20%) skewX(-80deg);
    transform-origin: bottom center;
  }
  97% {
    transform: translate(90%, -40%) skewX(-100deg);
    transform-origin: right;
  }
  to {
    transform: translate(90%, -40%) skewX(-90deg);
    transform-origin: right;
  }
}

@keyframes flicker {
  from {
    opacity: 0.9;
  }
  to {
    opacity: 1;
  }
}

@keyframes wobble {
  from {
    transform: translateX(50%) rotate(0);
  }
  50% {
    transform: translateX(70%) rotate(-360deg);
  }
  to {
    transform: translateX(50%) rotate(-720deg);
  }
}

</style>
