<template>
  <div class="tape display-6">
    <div :style="styleBindingContent">
      <slot>{{tapeContent}}</slot>
    </div>
  </div>
</template>

<script>
import {reactive} from "vue";

export default {
  name: 'TapeDivider',
  props: {
    rotation: {
      validator(value) {
        return ['down', 'up'].includes(value) || !isNaN(value)
      },
      default: "up"
    },
    content: {
      type: String,
      default: "SHACKERS"
    },
  },
  computed: {
    tapeContent() {
      if (this.content.length < 60) {
        return new Array(20).fill(this.content).join(" ");
      } else {
        return this.content;
      }
    },
    styleBindingContent() {
      return reactive({
        transform: `rotate(${this.rotationAngle}deg)`
      })
    },
    rotationAngle() {
      switch (this.rotation) {
        case 'down':
          return 5;
        case 'up':
          return -2.8;
        default:
          return parseFloat(this.rotation)
      }
    }
  }
}
</script>

<style scoped>
.tape {
  color: var(--tape-foreground-color, var(--action-foreground-color));
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  overflow-y: visible;
  overflow-x: clip;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 2rem;
}

.tape > div {
  background-color: var(--tape-background-color, var(--accent-color));
  font-family: 'ShackersCode', sans-serif;
  font-size: 65%;
  line-height: 100%;
  padding-bottom: 0.2rem;
  padding-top: 0.1rem;
  width: 200vw;
  white-space: nowrap;
}
</style>
