import {createApp, h} from "vue";
import {shortAddress, chainName} from "./utils/web3";
import {createWebHistory, createRouter} from "vue-router";
import Notifications from '@kyvg/vue3-notification'

import App from './App.vue';
import HomePage from "./pages/HomePage";
import TeamPage from "./pages/TeamPage";
import HuntMainPage from "./pages/HuntMain";
import MigrationPage from "./pages/MigrationPage";
import ClaimPage from "./pages/ClaimPage";
import ClaimKeyPage from "./pages/ClaimKeyPage";
import InteractiveOGPage from "./pages/InteractiveOGPage";
import InteractiveOG56 from "./components/og/InteractiveOG56";
import InteractiveOG73 from "./components/og/InteractiveOG73";
import RandomizerPage from "./pages/RandomizerPage";
import {flags} from "./store/flags";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {path: '/', name: 'home', component: HomePage},
    {path: '/nothing', name: 'nothing', component: HuntMainPage},
    {path: '/team', name: 'team', component: TeamPage},
    {path: '/og/migrate', name: 'migration', component: MigrationPage},
    {
      path: '/claim',
      name: 'claim',
      component: ClaimPage,
      beforeEnter: async () => (flags.getBoolean("mint_pages_enabled") ? true : {name: 'home'})
    },
    {path: '/claimKey', name: 'claimKey', component: ClaimKeyPage},
    {path: '/randomizer', name: 'randomizer', component: RandomizerPage},
    {path: '/randomizer/:requestId', name: 'randomizer', component: RandomizerPage},
    {
      path: '/og/interactive',
      name: 'interactive-og',
      component: InteractiveOGPage,
      meta: {hideFooter: true},
      children: [
        {path: '56', component: InteractiveOG56,},
        {path: '73', component: InteractiveOG73,},
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      component: HomePage
    }
  ],
});

router.beforeEach(async () => {
  await flags.initialize();
  return true;
})

const app = createApp({
  render: () => h(App)
});

app.use(router);
app.use(Notifications)

app.config.globalProperties.$filters = {
  shortAddress,
  chainName
};

app.mount("#app");
