<template>
  <ul class="navbar-nav nav-side text-center">
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
        <i class="icon-opensea_icon"></i>
      </a>
      <ul class="dropdown-menu">
        <li><a class="dropdown-item" href="https://opensea.io/collection/shackers-og" target="_blank">OG Shackers</a></li>
        <li><a class="dropdown-item" href="https://opensea.io/collection/shackers-keys" target="_blank">Keys</a></li>
        <li><a class="dropdown-item" href="https://opensea.io/collection/shackers-floor69" target="_blank">Floor 69</a></li>
      </ul>
    </li>
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
        <i class="icon-etherscan_icon"></i>
      </a>
      <ul class="dropdown-menu">
        <li><a class="dropdown-item" href="https://etherscan.io/address/0x4d9f6cc9d80fdf481a5f367343fdb11b208fee1f#code" target="_blank">OG Shackers</a></li>
        <li><a class="dropdown-item" href="https://etherscan.io/address/0x9b3F3A5FdE46C0543c888F3783bB0DDF3cD95533#code" target="_blank">OG Migration</a></li>
        <li><a class="dropdown-item" href="https://etherscan.io/address/0x2B410506f1cE2C60D4470abd04A88c15E23102E6#code" target="_blank">Randomizer</a></li>
        <li><a class="dropdown-item" href="https://etherscan.io/address/0x06b6058c48de9b365b07b901faacf19ea51b86b2#code" target="_blank">Keys</a></li>
      </ul>
    </li>
<!--    <li class="nav-item">-->
<!--      <a class="nav-link" href="" target="_blank">-->
<!--        <i class="icon-discord_icon"></i>-->
<!--      </a>-->
<!--    </li>-->
    <li class="nav-item">
      <a class="nav-link" href="https://twitter.com/shackersx" target="_blank">
        <i class="icon-twitter_icon"></i>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'LinkList',
  props: {},
  data() {
    return {}
  },
}
</script>

<style scoped>
.navbar-nav {
  flex-direction: row;
  justify-content: space-evenly;
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.nav-link {
  font-size: 1.5rem;
  color: var(--content-color) !important;
}

.navbar-nav .nav-link {
  padding: 0.5rem;
}

.nav-side a {
  transition: opacity 200ms ease;
}

.nav-side a:hover {
  opacity: 0.666;
}
</style>
