<template>
  <div class="og-wrapper" id="og56">
    <transition name="fade">
      <img v-if="variant === 'normal'" key="nor" class="og" src="../../assets/img/og/73.png">
      <img v-else-if="variant === 'alt'" key="alt" class="og" src="../../assets/img/og/73_alt.png">
    </transition>

    <button class="btn btn-sm btn-primary" @click="swap">
      <span v-if="variant === 'normal'">Holster Sword</span>
      <span v-if="variant === 'alt'">Wield Sword</span>
    </button>
  </div>
</template>

<script>
const storageKey = 'shackers-og-73';
let storedVariant = 'normal';

try {
  storedVariant = localStorage.getItem(storageKey) || 'normal';
} catch (e) {
  console.log("Saving state not supported");
}

export default {
  name: 'InteractiveOG73',
  data() {
    return {
      variant: storedVariant,
    }
  },
  methods: {
    swap: function() {
      this.variant = this.variant === 'normal' ? 'alt' : 'normal';

      try {
        localStorage.setItem(storageKey, this.variant);
      } catch (e) {
        console.log("Saving state not supported");
      }
    }
  }
};
</script>

<style>
#interactive {
  --background-color: #007ea9;
  --accent-color: #ffc801;
  --content-color: #d4d4d4;
  --action-foreground-color: #d4d4d4;
  --action-background-color: #0b5ed7;
}
</style>

<style scoped>
.og-wrapper {
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.og {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.btn {
  position: absolute;
  bottom: 20px;
  left: auto;
  right: auto;
}

.og-wrapper .btn:focus {
  color: inherit;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
