<template>
  <div id="home">
    <PageHeader title="" />

    <div class="title text-center">
      <i class="icon-logo_icon display-1"></i>
      <br>
      <h1 class="display-4">SHACKERS</h1>
    </div>

    <div class="container">
      <video role="presentation"
             playsinline preload="auto" muted loop autoplay
             tabindex="-1" style="width: 100%; height: 100%">
        <source src="../assets/media/portal.mp4" type='video/mp4; codecs="hvc1"'>
        <source src="../assets/media/portal.webm" type="video/webm">
      </video>
    </div>
  </div>
</template>

<script>
import PageHeader from "../components/PageHeader";

export default {
  name: 'HomePage',
  components: {
    PageHeader,
  },
}
</script>

<style scoped>
i {
  color: var(--accent-color);
}

h1 {
  color: var(--content-color);
}

</style>
