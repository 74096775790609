export const flameColors = {
  "yellow": "#F5FF00",
  "purple": "#9600FF",
  "orange": "#FF8000",
  "gray": "#7E7E7E",
  "black": "#000000",
  "white": "#FFFFFF",
  "red": "#FF003B",
  "green": "#00FF2F",
  "blue": "#00B4FF",
  "pink": "#FF00E0",
}

export const loaderColors = {
  "yellow": "#F5FF00",
  "purple": "#9600FF",
  "red": "#FF003B",
  "green": "#00FF2F",
  "blue": "#00B4FF",
  "pink": "#FF00E0",
}
