import {ethers} from "ethers";

export const createContract = function (contractAddress, contractAbi, web3) {
  const provider = new ethers.providers.Web3Provider(web3.provider);
  const contract = new ethers.Contract(contractAddress, contractAbi, provider);
  return contract.connect(provider.getSigner());
}

export * from "./claim"
export * from "./claimPass"
export * from "./migration"
export * from "./opensea"
export * from "./randomizer"
