<template>
  <div id="app" class="d-flex flex-column h-100">
    <router-view class="flex-shrink-0"></router-view>
    <div class="mt-auto" v-if="hasFooter">
      <PageFooter />
    </div>
    <notifications position="top right" :duration="5000"></notifications>
  </div>
</template>

<script>
import PageFooter from "./components/PageFooter";

export default {
  name: 'App',
  components: {PageFooter},
  data() {
    return {
      isActive: false
    }
  },
  computed: {
    hasFooter: function() {
      return !this.$route.meta ||
          (!this.$route.meta.hideFooter && !((this.$route.matched[0] || {}).meta || {}).hideFooter);
    }
  },
  methods: {
    toggleNav: function () {
      this.isActive = !this.isActive;
      if (this.isActive) {
        const x = window.scrollX;
        const y = window.scrollY;
        window.onscroll = function () {
          window.scrollTo(x, y);
        };
      } else {
        window.onscroll = function () {};
      }
    }
  }
}
</script>

<style>
@import 'bootstrap-icons/font/bootstrap-icons.css';
@import './assets/css/bootstrap.min.css';
@import './assets/css/shackicons.css';

@font-face {
  font-family: "Big500";
  src: url("./assets/fonts/Big500.otf"), url("./assets/fonts/Big500.ttf");
}

@font-face {
  font-family: "Big500";
  src: url("./assets/fonts/Big500Italic.otf"), url("./assets/fonts/Big500Italic.ttf");
  font-style: italic;
}

@font-face {
  font-family: "ShackersCode";
  src: url("./assets/fonts/Shackers-Code-Regular.ttf");
}

:root {
  --background-image: url("~@/assets/img/starfield.png") center / contain scroll repeat;;
  --background-color: #000000;
  --accent-color: red;
  --content-color: #d4d4d4;
  --action-foreground-color: #12001f;
  --action-background-color: red;
}

html {
  height: 100%;
}

body {
  font-family: 'Archivo', sans-serif;
  min-height: 100vh;
}

* {
  transition: all 150ms linear;
}

#app {
  min-height: 100vh;
}

#app > div:first-child {
  color: var(--content-color);
}

#app > div:first-child::after {
  content: " ";
  position: fixed;
  left: 0;
  top: -100px;
  right: 0;
  bottom: -100px;
  background: var(--background-image);
  background-color: var(--background-color);
  z-index: -1;
}

h1 {
  font-family: "Big500", sans-serif;
  color: var(--accent-color);
  font-size: clamp(20px, 16vw, 235px);
  line-height: 100%;
  margin-top: -1rem;
}

h1 {
  white-space: nowrap;
}

h1 i {
  font-size: 50%;
  vertical-align: middle;
}

h2, h3, h4, h5, h6 {
  font-family: 'Teko', sans-serif;
  font-weight: 700;
  color: var(--content-color);
}

h2, h2.display-2 {
  text-transform: uppercase;
  line-height: 80%;
}

.btn {
  font-weight: 900;
  text-transform: uppercase;
}

.btn-primary {
  --bs-btn-color: var(--action-foreground-color);
  --bs-btn-bg: var(--action-background-color);
  --bs-btn-border-color: var(--action-background-color);
  --bs-btn-hover-color: var(--action-foreground-color);
  --bs-btn-hover-bg: var(--action-background-color);
  --bs-btn-hover-border-color: var(--action-background-color);
  --bs-btn-focus-shadow-rgb: 255,255,255;
  --bs-btn-active-color: var(--action-foreground-color);
  --bs-btn-active-bg: var(--action-background-color);
  --bs-btn-active-border-color: var(--action-background-color);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: grey;
  --bs-btn-disabled-bg: rgba(13, 110, 253, 0.5);
  --bs-btn-disabled-border-color: rgba(13, 110, 253, 0.5);
}

.btn-primary:hover {
  opacity: 0.8;
}

.highlight {
  color: var(--highlight-color, var(--accent-color));
}

.bg-highlight {
  background-color: var(--highlight-color, var(--accent-color));
}

.border-highlight {
  border: 4px solid var(--highlight-color, var(--accent-color));
}

.border-accent {
  border: 4px solid var(--accent-color);
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.85;
}

a {
  cursor: pointer;
}

a.external::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAVklEQVR4Xn3PgQkAMQhDUXfqTu7kTtkpd5RA8AInfArtQ2iRXFWT2QedAfttj2FsPIOE1eCOlEuoWWjgzYaB/IkeGOrxXhqB+uA9Bfcm0lAZuh+YIeAD+cAqSz4kCMUAAAAASUVORK5CYII=);
  margin: 0 3px 0 5px;
}

.container-boxed {
  border-width: 4px 0;
  border-style: solid;
  border-color: var(--boxed-border-color, var(--content-color));
}

section {
  padding: 0;
  margin: 2rem 0 1rem;
}

/*section ~ section {*/
/*  padding-top: 0;*/
/*}*/

@media (min-width:576px) {
  .container-boxed {
    border-width: 4px;
  }
}

@media (min-width: 576px) {
  section {
    padding: 0 2rem 0;
    margin: 3rem 0 2rem;
  }
}

@media (min-width: 992px) {
  section {
    padding: 0 3rem 0;
    margin: 4rem 0 3rem;
  }
}

@media (min-width: 1200px) {
  section {
    padding: 0 5rem 0;
    margin: 5rem 0 4rem;
  }
}

#app .vue-notification-wrapper {
  overflow: inherit;
}

#app .vue-notification {
  font-size: 16px;
  padding: 20px 15px;
  border-radius: 8px;
  margin: 15px 15px 0 15px;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
}

#app .vue-notification.warn {
  background: #f48a06;
}
</style>
