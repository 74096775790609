<template>
  <div id="interactive">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'InteractiveOGPage',
  data() {
    return {
    }
  }
};
</script>

<style>
#interactive {
  --background-color: #000000;
  --accent-color: #fdf400;
  --content-color: #d4d4d4;
  --action-foreground-color: #12001f;
  --action-background-color: #fdf400;
}
</style>
