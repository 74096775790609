<script setup>
import PageHeader from "../components/PageHeader";
import {ref} from "vue";

const step = ref(0)
const nextStep = () => step.value += 1
const dieStep = () => step.value = 0
</script>

<template>
  <div id="home">
    <PageHeader title=""/>

    <div class="container">
      <Transition mode="out-in">
        <div v-if="step === 0">
          <img class="w-100 d-none d-lg-inline-block" src=../assets/img/hunt/nothing.jpg @click="nextStep"/>
          <img class="w-100 d-inline-block d-lg-none" src=../assets/img/hunt/nothing_portrait.jpg @click="nextStep"/>
        </div>
        <div v-else-if="step === 1">
          <img src="../assets/img/hunt/hunt_1.jpg"/>
          <p class="display-6">
            Jim and Pedro were lost in the forest while filming a YouTube video about a supposedly haunted cabin.
            They thought they were lost forever, but then small flames started guiding them out.
          </p>
          <p class="text-center display-6 highlight">
            Q:HOW MANY TYPES OF FLAMES EXIST IN THE SHACK WORLD?
          </p>
          <p class="answers">
            <button class="btn btn-primary" @click="dieStep">4</button>
            <button class="btn btn-primary" @click="nextStep">7</button>
          </p>
        </div>
        <div v-else-if="step === 2">
          <img src="../assets/img/hunt/hunt_2.jpg"/>
          <p class="display-6">
            The flames led them to a small, dilapidated Shack, where eerie blue lights seemed to emanate from within.
            It was quite unsettling.
          </p>
          <p class="text-center display-6 highlight">
            Q: WHAT IS INSIDE OF THE SHACK?
          </p>
          <p class="answers">
            <button class="btn btn-primary" @click="dieStep">A HAUNTED HOUSE</button>
            <button class="btn btn-primary" @click="nextStep">A SECRET ORGANIZATION</button>
          </p>
        </div>
        <div v-else-if="step === 3">
          <img src="../assets/img/hunt/hunt_3.jpg"/>
          <p class="display-6">
            Jim and Pedro decided to investigate the shack further by entering it. Inside, they discovered a peculiar
            elevator with a digital keypad on its side, adorned with strange symbols.
            Above the keypad, the word "S.H.A.C.K" was inscribed.
          </p>
          <p class="text-center display-6 highlight">
            Q: WHAT DOES S.H.A.C.K. STAND FOR?
          </p>
          <p class="answers">
            <button class="btn btn-primary" @click="nextStep">SAFE HOUSE OF ALIEN CULTURE AND KNOWLEDGE</button>
            <button class="btn btn-primary" @click="dieStep">SUPER HEALTHY AVOCADO COOLING KITCHEN</button>
          </p>
        </div>
        <div v-else-if="step === 4">
          <img src="../assets/img/hunt/hunt_4.jpg"/>
          <p class="display-6">
            "SURPRIIIIIISE!!!” Jim and Pedro were startled when suddenly the elevator doors opened, revealing a strange
            little character who emerged from behind them shouting. The creature looked at them and said,
            "If you can answer my question, I'll take you down to the portal. It's way underground, on the last floor."
          </p>
          <p class="text-center display-6 highlight">
            Q: DO YOU KNOW MY NAME?
          </p>
          <p class="answers">
            <button class="btn btn-primary" @click="dieStep">WALFI</button>
            <button class="btn btn-primary" @click="nextStep">WAFFY</button>
          </p>
        </div>
        <div v-else-if="step === 5">
          <img src="../assets/img/hunt/hunt_5.jpg"/>
          <p class="display-6">
            "HERE WE ARE, LAAAAST FLOOOOR!!! EVERYONE OUUUT TOOOT TOOOOOT!" Waffy exclaimed excitedly.
            He turned to Jim and Pedro and said, "If you can answer this question, I'll give you this beautiful key.
            Are you ready? Here it is:"
          </p>
          <p class="display-6">
            Before becoming SHACK CEO, the creator worked as an artist for a renowned animation studio.
          </p>
          <p class="text-center display-6 highlight">
            Q: DO YOU KNOW WHICH ONE?
          </p>
          <p class="answers">
            <button class="btn btn-primary" @click="nextStep">DREAMWORKS ANIMATION</button>
            <button class="btn btn-primary" @click="dieStep">PIXAR</button>
          </p>
        </div>
        <div v-else-if="step === 6">
          <img src="../assets/img/hunt/hunt_note.jpg"/>
          <p class="answers">
            <a href="#" class="btn btn-primary" @click="nextStep">Enter via Premint</a>
          </p>
        </div>

      </Transition>
    </div>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

img {
  display: block;
  max-width: 100%;
  margin: 2rem auto;
}

.answers {
  text-align: center;
}

.answers .btn {
  min-width: 100px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.container div p {
  max-width: 1060px;
  margin-left: auto;
  margin-right: auto;
}
</style>
