<template>
  <div class="d-inline-block">
    <button v-if="!shared.web3.connected" class="btn btn-primary mb-2" @click="connect">
      <span>Connect wallet</span>
    </button>
<!--    <button v-else-if="!shared.web3.connectedToRequiredNetwork" class="btn btn-primary mb-2" @click="switchNetwork">-->
<!--      <span>Switch network to {{requiredNetwork}}</span>-->
<!--    </button>-->
    <div v-if="shared.web3.connected && showLoggedIn">
      <span>
        Connected as
        <span class="connectedAddress" :class="{'rainbow': changedAddress}">
          {{ $filters.shortAddress(shared.web3.address) }}&nbsp;
        </span>
      </span>
      <a href="#" @click="disconnect">Disconnect</a>
    </div>
  </div>
</template>

<script>
import {REQUIRED_NETWORK, Store} from "../store";

export default {
  name: 'SignIn',
  props: {
    showLoggedIn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      shared: Store.state,
      changedAddress: false,
      requiredNetwork: REQUIRED_NETWORK
    }
  },
  created() {
    Store.tryAutoConnectWallet();
  },
  watch: {
    "shared.web3.address"(newAddress, oldAddress) {
      if (newAddress !== oldAddress) {
        this.changedAddress = true;
        setTimeout(() => {
          this.changedAddress = false;
        }, 4000);
      }
    }
  },
  methods: {
    connect: async function () {
      await Store.connectWalletModal();
    },
    disconnect: async function () {
      await Store.resetConnection();
    },
    switchNetwork: async function () {
      await Store.switchToRequiredNetwork();
    },
  }
}
</script>

<style scoped>
.connectedAddress {
  color: white;
}

.rainbow {
  animation: rainbow 3s linear;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}

@keyframes rainbow{
  0%{
    color: rgb(255,0,255);
  }
  11%{
    color: rgb(0,255,255);
  }
  22% {
    color: rgb(255,255,0);
  }
  33%{
    color: rgb(255,0,255);
  }
  44%{
    color: rgb(0,255,255);
  }
  55% {
    color: rgb(255,255,0);
  }
  66%{
    color: rgb(255,0,255);
  }
  77%{
    color: rgb(0,255,255);
  }
  88% {
    color: rgb(255,255,0);
  }
  100% {
    color: white;
  }
}
</style>
