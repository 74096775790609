// global filters
function shortAddress(address) {
  return address ? `${address.substr(0, 6)}..${address.substr(-4)}` : "";
}

function chainName(chainId) {
  switch (chainId) {
    case 1:
      return "Ethereum";
    case 4:
      return "Rinkeby";
    case 137:
      return "Polygon";
    case 80001:
      return "Polygon (Test)";
    case 56:
      return "BSC";
    case 97:
      return "BSC (Test)";
    case 43114:
      return "Avalanche";
    case 43113:
      return "Avalanche (Test)";
    default:
      return `Chain #${chainId}`;
  }
}

function txUrl(transactionHash) {
  return transactionHash ? `https://etherscan.io/tx/${transactionHash}` : null;
}

function openSeaWalletUrl(address, web3) {
  const subdomain = web3 && web3.chainId === 1 ? "" : "testnets.";
  return address ? `https://${subdomain}opensea.io/${address}` : null;
}

function extractWeb3ErrorMessage(error) {
  const defaultErrorMessage = 'An error occurred'
  const message = error.reason ??
    error.data?.message ??
    error.message ??
    defaultErrorMessage

  const reasonStringMatch = message.match(/with reason string '(.*)'$/)
  const nextedJsonMatch = message.match(/"code":-?\d*,"message":"([^"]*)"/)
  if (reasonStringMatch && reasonStringMatch[0]) {
    return reasonStringMatch[1]
  } else if (nextedJsonMatch && nextedJsonMatch[0]) {
    return nextedJsonMatch[1]
  } else {
    return message
  }
}

export {chainName, openSeaWalletUrl, shortAddress, txUrl, extractWeb3ErrorMessage}
