<template>
  <div id="team">
    <PageHeader title="Team"/>

    <div class="container container-boxed">
      <div class="row profile profile-featured pt-5 pb-3">
        <div class="col-lg-4 text-center">
          <img src="../assets/img/team/shack.gif" class="rounded-circle border-accent mb-4" style="width: 100%"/>
        </div>
        <div class="col-lg-5 text-center text-lg-start">
          <h2>Sébastien Wojda</h2>
          <h5 class="headline">
            Founder / Artist
            <br>
            <a href="https://twitter.com/SHACKERsNFT" target="_blank" @click="takeInteractionStep(1)">
              <i class="bi bi-twitter"></i>
              @SHACKERsNFT
            </a>
            &nbsp;
            <a href="https://www.imdb.com/name/nm5974462/" target="_blank" @click="takeInteractionStep(2)">
              <img class="headline-logo" src="../assets/img/team/logo-imdb.png"/>
            </a>
          </h5>
          <p class="highlight">
            An animator with {{ yearsExp }} years of success at Dreamworks Animation. Having worked on many movies
            during that time - Shrek, How to Train Your Dragon, Kung Fu Panda, Madagascar & more. Sébastien aka Shack
            has a passion for telling stories and building worlds.
          </p>
          <p class="highlight">
            The SHACKERS project combines all the ideas, stories and drawings he’s worked on over the years.
            “It represents everything I love and want to share with the world.”
            Keep an eye on Shack he’s got a thing for trickery and puzzles.
          </p>
        </div>
        <div class="col-lg-3">
          <div class="reel-content text-center">
            <a href="#"
               data-bs-toggle="modal" data-bs-target="#reel"
               @click="() => {this.selectedReelId = 'GOe92tBTXH8';takeInteractionStep(3)}">
              <figure class="figure">
                <img src="../assets/img/team/reel2020.jpg"
                     class="figure-img img-fluid rounded"
                     alt="Preview of Animation Reel">
                <figcaption class="figure-caption">Animation Reel 2020</figcaption>
              </figure>
            </a>
            <br>
            <a href="#"
               data-bs-toggle="modal" data-bs-target="#reel"
               @click="() => {this.selectedReelId = 'FCeYPgmARVI';takeInteractionStep(4)}">
              <figure class="figure">
                <img src="../assets/img/team/reel2019.jpg"
                     class="figure-img img-fluid rounded"
                     alt="Preview of Animation Reel">
                <figcaption class="figure-caption">Animation Reel 2019</figcaption>
              </figure>
            </a>
          </div>
        </div>
      </div>

      <TapeDivider rotation="0" :style="riddleStyleBinding" v-on="interactionComplete ? {click: copyResult} : {}">
        <span v-for="(riddleFragment, index) of riddleContent" v-bind:key="index">{{riddleFragment}}&nbsp;</span>
      </TapeDivider>

      <div class="row mt-5 pt-4 team-members text-center">
        <div class="col-lg-4 text-lg-start profile">
          <img src="../assets/img/team/loltapes.gif" class="rounded-circle border-accent mb-4"/>
          <h2 class="mb-4">LolTapes</h2>
          <h5 class="headline">
            Engineering / Tech
            <br>
            <a href="https://twitter.com/loltapes" target="_blank" @click="takeInteractionStep(5)">
              <i class="bi bi-twitter"></i>
              @loltapes
            </a>
          </h5>
          <p>
            Generalist IT Engineer with {{ yearsExp }} years experience in the industry across the stack with a
            passion for
            crafting products. Got hooked on NFTs after creating his own project in March 2021 and went in full-time.
          </p>
          <p>
            LolTapes has worked on a variety of NFT projects including software license, memberships and PFPs
            but it's the SHACKERS project which gives him the freedom to experiment and keep him on his toes.
          </p>
        </div>

        <div class="col-lg-4 text-lg-center profile">
          <img src="../assets/img/team/future.gif" class="rounded-circle border-accent mb-4"/>
          <h2 class="mb-4">Future</h2>
          <h5 class="headline">
            Community / Partnerships
            <br>
            <a href="https://twitter.com/Futurefanatik" target="_blank" @click="takeInteractionStep(6)">
              <i class="bi bi-twitter"></i>
              @Futurefanatik
            </a>
          </h5>
          <p>
            Nick (Future) first found Shackers as an early supporter and fan of the project.
            His contributions early on set the tone for the community and its his vision for the project that earned him
            a spot at the Shackers round table.
          </p>
          <p>
            Nick has a passion for connecting people and has made a career of it, having worked with multiple startups
            and fortune 100 companies. He now looks to the Metaverse to build something he truly believes in:
            Waffles and !tatas
          </p>
        </div>

        <div class="col-lg-4 text-lg-end profile">
          <img src="../assets/img/team/capone.gif" class="rounded-circle border-accent mb-4"/>
          <h2 class="mb-4">Capone</h2>
          <h5 class="headline">
            Advisor / Strategist
            <br>
            <a href="https://twitter.com/NFTupdate" target="_blank" @click="takeInteractionStep(7)">
              <i class="bi bi-twitter"></i>
              @NFTupdate
            </a>
          </h5>
          <p>
            Social media, marketing & strategy. Community builder and private Discord owner for 4+ years,
            with experience in emerging markets and secondary sales.
          </p>
          <p>
            Also a street apparel and collectibles enthusiast.
            Joined NFT Twitter in March 2021 and has been a Twitter Spaces host and educator since.
          </p>
        </div>
      </div>

      <div class="staff row">
        <div class="col-12 col-lg-6">
          <TapeDivider rotation="13"/>
          <img src="../assets/img/team/staff.png" class="staff-image"/>
        </div>

        <div class="staff-list col-12 col-lg-6 text-center text-lg-end">
          <span class="text-decoration-underline">Supported in the SHACK by our lovely staff:</span>
          <ul>
            <li><span class="highlight">Pamela</span> - Back office secretary &amp; head of archive</li>
            <li><span class="highlight">Karen</span> - Receptionist; double agent suspect</li>
            <li><span class="highlight">Jacqueline</span> - Tech Whiz and Waffle maker</li>
            <li><span class="highlight">Gerard</span> - Demolition Department</li>
            <li><span class="highlight">Henry</span> - Chemicals Department</li>
            <li><span class="highlight">Podrick</span> - V.I.P. Butler</li>
            <li>
              and <span class="highlight">WAFFY</span>!
              <br>
              <img src="../assets/img/team/waffy.gif" />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="modal fade" id="reel" tabindex="-1" aria-labelledby="reelDemoLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <iframe width="560" height="315"
                  :src="`https://www.youtube.com/embed/${selectedReelId}`"
                  title="YouTube video player"
                  frameborder="0"
                  allow="autoplay; encrypted-media; picture-in-picture"
                  allowfullscreen>
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import TapeDivider from "../components/TapeDivider";
import {flags} from "../store/flags";

const REWARD = [
    "unfold stable pretty",
    "limit utility plastic",
    "shield advice dolphin skin",
    "swamp mansion together light",
    "execute valve unusual",
    "hurt jazz anchor",
    "slender recycle build chair",
    "will waste cricket patrol",
    "supreme voyage radio",
    "column episode amazing",
    "empty turkey hawk settle",
    "radio mesh situate clown",
    "soon chicken tenant",
    "monkey ranch pioneer",
];

const MAX_INTERACTION_STEPS = 7;
const PLACEHOLDER = "RIDDLE";

export default {
  name: 'TeamPage',
  components: {
    PageHeader,
    TapeDivider,
  },
  data() {
    return {
      selectedReelId: null,
      interactionStepsTaken: new Array(MAX_INTERACTION_STEPS).fill(false),
    }
  },
  computed: {
    riddleContent() {
      if (!flags.getBoolean("team_page_riddle_enabled")) {
        return new Array(24).fill("SHACKERS");
      }

      if (this.interactionStepsCount === 0) {
        return new Array(24).fill(PLACEHOLDER);
      } else {
        const reward = [];
        for (let i = 0; i < MAX_INTERACTION_STEPS; i++) {
          if (this.interactionStepsTaken[i]) {
            reward.push(REWARD[(i * 2) + 1]);
          } else {
            reward.push(REWARD[(i * 2) + 1].split(" ").map(() => PLACEHOLDER).join(" "));
          }
        }
        return reward;
      }
    },
    riddleStyleBinding() {
      if (this.interactionComplete) {
        return {
          color: "var(--accent-color)",
          pointerEvents: "all",
          justifyContent: "start",
          overflowX: "scroll",
          backgroundColor: "white"
        }
      } else {
        return {};
      }
    },
    interactionComplete() {
      return this.interactionStepsCount === MAX_INTERACTION_STEPS;
    },
    interactionStepsCount() {
      return this.interactionStepsTaken.filter((value) => value === true).length
    },
    yearsExp() {
      // shack and lol both started 2009
      return new Date().toISOString().substring(0, 4) - 2009;
    }
  },
  methods: {
    takeInteractionStep(stepId) {
      this.interactionStepsTaken[stepId - 1] = true;
    }
  }
}
</script>

<style scoped>
#team {
  --accent-color: #F702D0;
  --highlight-color: #00F0FF;
  --boxed-border-color: var(--accent-color);
  --tape-foreground-color: #3724cb;
  --tape-background-color: white;
}

@media (min-width: 576px) {
  #team .modal {
    --bs-modal-width: 560px;
  }
}

@media (min-width: 992px) {
  #team .modal {
    --bs-modal-width: 900px;
  }
}

#reel iframe {
  width: 100%;
  aspect-ratio: 1.7;
  height: auto;
}

.profile img {
  max-width: 50%;
}

@media (min-width: 992px) {
  .profile-featured img {
    max-width: 90%;
  }
}

.profile h2 {
  font-size: 3em;
}

.profile .headline {
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.profile .headline a {
  color: var(--content-color);
}

.headline-logo {
  max-height: 1rem;
  vertical-align: middle;
}

.profile p {
  color: var(--highlight-color);
  text-align: start;
}

.team-members .profile > * {
  margin-left: 2rem;
  margin-right: 2rem;
}

.staff {
  flex-direction: column-reverse;
}

.staff-image {
  width: 100%;
  position: relative;
  margin-top: -7%;
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

.staff-list {
  margin-top: 2rem;
  text-align: end;
  padding-bottom: 1rem;
}

@media (min-width: 992px) {
  .staff {
    flex-direction: row;
    margin-top: 8rem;
    align-items: flex-end;
  }

  .staff-image {
    margin-top: -19%;
  }

  .staff-list {
    margin-top: -7%;
  }

  .profile p {
    text-align: center;
  }

  .profile:first-child p {
    text-align: start;
  }

  .profile:last-child p {
    text-align: end;
  }
}

.staff-list ul {
  list-style: none;
  padding: 0;
}
</style>
