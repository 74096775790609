<template>
  <a :href="contractUrl" target="_blank" class="link-secondary">
    <i class="icon-etherscan_icon"></i>
    Verified Smart Contract
  </a>
</template>

<script>
export default {
  name: 'ContractUrl',
  props: {
    contractAddress: String
  },
  data() {
    return {
      network: process.env.VUE_APP_ETH_NETWORK,
    }
  },
  computed: {
    contractUrl: function () {
      const subdomain = this.network !== 'mainnet' ? `${this.network}.` : '';
      return `https://${subdomain}etherscan.io/address/${this.contractAddress}#code`
    }
  }
}
</script>
