<script setup>
import {ref, defineEmits, defineExpose, defineProps, computed} from 'vue'
import LoadingIndicator from "./LoadingIndicator";
import {naturalSort} from "../utils/arrays";

const emit = defineEmits(['assetsLoad', 'selectionUpdate'])

const props = defineProps({
  title: String,
  slug: String,
  ownerAddress: String,
  hideControls: Boolean,
  marks: {
    type: Object,
    default(rawProps) {
      return rawProps || {};
    }
  },
})

// reactive state
const assets = ref(null)
const loading = ref(false)
const error = ref(null)

// functions that mutate state and trigger updates
const ownerAddress = props.ownerAddress;
const API_BASE_URI = process.env.VUE_APP_API_BASE_URI;
const endpoint = API_BASE_URI.indexOf("localhost") === -1 ? `${API_BASE_URI}/assetsForOwner` : `${API_BASE_URI}/loadOpenSeaAssets`;
const url = `${endpoint}?slug=${props.slug}&ownerAddress=${ownerAddress}`;
const options = {method: "GET"};

const load = function () {
  loading.value = true;
  error.value = null;
  fetch(url, options)
      .then((res) => res.json())
      .then((json) => {
        if (!json.assets) {
          console.log("Response did not contain assets", json);
          error.value = "Assets could not be loaded";
        } else {
          assets.value = json.assets.sort((a, b) => naturalSort(a.tokenId, b.tokenId));
          emit('assetsLoad', assets.value);
        }
        loading.value = false;
      })
      .catch((err) => {
        error.value = `Api Error ${err}`;
        loading.value = false;
      })
}

load();
// loading.value = true;
// setTimeout(load, 400);

const tokenMarks = computed(() => props.marks);

const selectedTokenIds = ref([])

function selectToken(tokenId) {
  const existingIndex = selectedTokenIds.value.indexOf(tokenId);
  if (existingIndex === -1) {
    selectedTokenIds.value.push(tokenId);
  } else {
    selectedTokenIds.value.splice(existingIndex, 1);
  }
  emit('selectionUpdate', selectedTokenIds.value);
}

function selectAll() {
  selectedTokenIds.value = assets.value ? assets.value.map((t) => t.tokenId).filter((id) => !props.marks[id]) : [];
  emit('selectionUpdate', selectedTokenIds.value);
}

function selectNone() {
  if (selectedTokenIds.value.length > 0) {
    selectedTokenIds.value = [];
    emit('selectionUpdate', selectedTokenIds.value);
  }
}

defineExpose({selectAll, selectNone})
</script>

<template>
  <section>
    <slot name="title"></slot>

    <div v-if="error">
      Error loading assets.
      <br>
      <a class="btn btn-sm btn-secondary mt-3" @click.prevent="load()">
        Retry
        <i class="bi bi-arrow-counterclockwise"></i>
      </a>
    </div>
    <div v-else-if="!assets">
      <slot name="loading">
        <LoadingIndicator color="random" :delay="0"/>
      </slot>
    </div>
    <div class="text-center" v-else-if="assets.length">
      <p v-if="!hideControls" class="text-end">
        <a class="btn btn-secondary mb-2" @click.prevent="selectAll()">Select All</a>
        <a class="btn btn-secondary mb-2 ms-2" @click.prevent="selectNone()">Select None</a>
      </p>
      <div class="row tokens">
        <div v-for="token in assets" :key="token.tokenId"
             class="d-inline-block position-relative col-6 col-md-4 col-lg-3">
          <a class="token-preview" href="#"
             :class="{selected: selectedTokenIds.indexOf(token.tokenId) !== -1}"
             @click.prevent="selectToken(token.tokenId)"
          >
            <img :src="token.imagePreviewUrl"/>
            <h4>{{ token.name }}</h4>
          </a>
          <slot v-if="tokenMarks[token.tokenId]" name="mark" :mark="tokenMarks[token.tokenId]" :token="token"></slot>
        </div>

      </div>
    </div>
    <div v-else>
      <slot name="empty">
        <p>
          No {{ title }} found.
          You may get some on <a :href="`https://opensea.io/collection/${slug}`" target="_blank">OpenSea</a>.
        </p>
      </slot>
    </div>
  </section>
</template>

<style scoped>

.token-preview {
  text-decoration: none;
}

.token-preview img {
  border: 4px solid transparent;
  border-radius: .5rem;
  width: 100%;
}

.token-preview.selected img {
  border-color: var(--action-background-color);
}

.token-preview.selected h4 {
  color: var(--action-background-color);
}

.token-preview:hover img {
  opacity: 0.7;
}

</style>
