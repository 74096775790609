<template>
  <header class="header pt-2">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container">

        <router-link class="navbar-brand nav-link" :to="{ name: 'home' }">
          <i class="icon-logo_icon"></i>
        </router-link>
        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-collapse collapse" id="navbarNav">
          <ul class="navbar-nav flex-grow-1 justify-content-center">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'home' }">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'team' }">Team</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'migration' }">Migrate your OG Shacker</router-link>
            </li>
          </ul>
          <LinkList/>
        </div>
      </div>
    </nav>

    <div class="container" v-if="title">
      <h1>{{ title }}<i class="icon-logo_icon"></i></h1>
    </div>
  </header>
</template>

<script>
import LinkList from "./LinkList";

export default {
  name: 'PageHeader',
  components: {LinkList},
  props: ['title']
}
</script>

<style scoped>
nav {
  text-transform: uppercase;
}

.nav-item {
  margin-right: 1rem;
}

.navbar-brand {
  font-size: 3rem;
}

.navbar .navbar-nav .nav-link {
  font-family: 'Archivo Black', sans-serif;
  color: var(--content-color);
  transition: opacity 200ms ease;
}

.nav-link:hover {
  opacity: 0.666;
}

#navbarNav .router-link-exact-active::before {
  content: "> ";
}

@media (min-width: 992px) {
  #navbarNav .router-link-exact-active {
    border-bottom: 4px solid var(--content-color);
  }

  #navbarNav .router-link-exact-active::before {
    content: "";
  }
}


</style>
