<template>
  <footer class="footer mt-auto">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-auto text-center display-4 logomark">
          <i class="icon-logo_land"></i>
        </div>
        <div class="col text-center d-none d-lg-block">
          <LinkList />
        </div>
        <div class="col-12 col-lg-auto text-center">
          <span>©2022 All Rights Reserved. SHACKERS</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import LinkList from "./LinkList";
export default {
  name: 'PageFooter',
  components: {LinkList},
  props: {},
}
</script>

<style scoped>
footer {
  padding: 1rem;
  background-color: var(--footer-bg-color, #000);
  color: white;
}

.logomark {
  vertical-align: text-bottom;
}

.logomark i:before {
  width: auto;
}

footer .row {
  align-items: center;
}

@media (min-width: 992px) {
  footer {
    padding: 2.5rem 5rem;
  }
}
</style>
